import {
  CashIcon,
  ChatIcon,
  CompoundCutOutIcon,
  GlobalIcon,
  LightningIcon,
  MergeIcon,
  PeopleIcon,
  PlanningIcon,
  QuestionMarkIcon,
  RetailIcon,
  SettingsIcon,
  UnionIcon,
} from "@compoundfinance/design-system";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { NavigationItem, NavigationRoutes } from "../models";
import Badge from "components/fundamentals/Badge";
import { FlashingNotificationDot } from "components/fundamentals/FlashingNotificationDot";
import { NonExclusiveUnion } from "shared/types";
import classNames from "shared/utilities/classNames";

export const ADVISOR_HELP_NAV_ITEM: NavigationItem = {
  name: "How-To Google Doc",
  href: "https://docs.google.com/document/d/1RaWtg_gjJBvcwiB3l1fLixxhIpCFIiGW1dP_otpjAcc/edit?usp=sharing",
  enabled: true,
  isExternalLink: true,
};

interface Props {
  readonly navigationItem: NavigationItem;
  readonly notificationCount?: number;
  readonly activeNav?: NavigationRoutes;
  readonly expanded: boolean;
  readonly suffix?: ReactNode;
}

const getNavigationIcon = (
  route: NonExclusiveUnion<NavigationRoutes>,
  hasNotifications: boolean,
  isExpanded: boolean
) => {
  switch (route) {
    case "/advisorhq":
      return (
        <div className="relative">
          <UnionIcon />
          {hasNotifications && !isExpanded && (
            <FlashingNotificationDot color="red" position="bottom" size="small" />
          )}
        </div>
      );
    case "/clients":
      return <PeopleIcon />;
    case "/models":
      return <GlobalIcon />;
    case "/trade":
      return <MergeIcon />;
    case "/discover":
      return <RetailIcon />;
    case "/proposals":
      return <PlanningIcon />;
    case "/service":
      return <ChatIcon />;
    case "/billing":
      return <CashIcon />;
    case "/admin":
      return <SettingsIcon />;
    case "/admin/accounts-attention":
      return (
        <div className="relative">
          <LightningIcon />
          {hasNotifications && !isExpanded && (
            <FlashingNotificationDot color="red" position="bottom" size="small" />
          )}
        </div>
      );
    case "/client-dashboard-tools":
      return <CompoundCutOutIcon />;
    case ADVISOR_HELP_NAV_ITEM.href:
      return <QuestionMarkIcon />;
    default:
      return null;
  }
};

const getNotificationBadge = (notificationCount?: number) => {
  if (!notificationCount || notificationCount <= 0) {
    return null;
  }

  return (
    <span className="ml-3">
      <Badge additionalStyleClasses="bg-[#EB5353]">
        <span className="text-white">{notificationCount.toString()}</span>
      </Badge>
    </span>
  );
};

const NavigationBarItem = (props: Props) => {
  const router = useRouter();
  // Either map the href to the router path (which could be totally unrelated to the navbar href) OR
  // explicitly set the href that should be highlighted in the navbar
  const isCurrentPage =
    router.asPath.startsWith(props.navigationItem.href) ||
    (props.activeNav && props.navigationItem.href === props.activeNav);

  return (
    <div
      className={classNames(
        "bg-white flex w-full items-center align-middle px-1.5 py-1",
        props.expanded && "pr-6"
      )}
    >
      <Link
        href={props.navigationItem.href}
        passHref
        target={props.navigationItem.isExternalLink ? "_blank" : undefined}
        title={props.navigationItem.name}
        className={classNames(
          "flex justify-center font-medium p-2 hover:bg-gray-200 rounded-lg",
          props.expanded && "w-full",
          isCurrentPage && "bg-gray-200"
        )}
        data-navigation={`${props.navigationItem.name}`}
        data-selected={isCurrentPage}
      >
        {getNavigationIcon(
          props.navigationItem.href,
          (props.notificationCount ?? 0) > 0,
          props.expanded
        )}
        {props.expanded && (
          <div className="flex flex-1 items-center pl-4 whitespace-nowrap text-alternativ-monochrome-dark">
            {props.navigationItem.name}
            {getNotificationBadge(props.notificationCount)}
          </div>
        )}
        {props.expanded && props.suffix}
      </Link>
    </div>
  );
};

export default NavigationBarItem;
