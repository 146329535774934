import Image from "next/image";
import { passthroughLoader } from "./helpers";
import classNames from "shared/utilities/classNames";

interface DataProps {
  readonly avatar: string;
  readonly name: string;
  readonly testLocator?: string;
  readonly height?: number;
  readonly width?: number;
  readonly iconBorderRadius?: "full" | "large";
}

export const Avatar = (props: DataProps) => (
  <Image
    loader={passthroughLoader}
    src={props.avatar}
    alt={`Profile picture of ${props.name}`}
    className={classNames(
      "flex-shrink-0",
      props.iconBorderRadius === "large" ? "rounded-lg" : "rounded-full"
    )}
    height={props.height ? `${props.height}` : "40"}
    width={props.width ? `${props.width}` : "40"}
    data-testid={props.testLocator}
    referrerPolicy="no-referrer"
    unoptimized
  />
);
