import React from "react";
import { LoadingIcon } from "./icons/LoadingIcon";
import classNames from "shared/utilities/classNames";

type NativeButtonProps = Pick<React.ComponentProps<"button">, "disabled" | "children" | "form">;

interface ButtonInnerProps extends NativeButtonProps {
  readonly size?: buttonSizes;
  readonly isSubmit?: boolean;
  readonly isPrimary?: boolean;
  readonly fullWidth?: boolean;
  readonly showLoadingIconOnSubmitting?: boolean;
  readonly submitting?: boolean;
  readonly linkButton?: boolean;
  readonly additionalStyleClasses?: string;
  readonly variant?: "compound";
}

export interface ButtonProps extends ButtonInnerProps {
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
  readonly dataAt?: string;
}

const buttonColorClasses = (
  isPrimary?: boolean,
  disabled?: boolean,
  linkButton?: boolean,
  variant?: "compound"
) => {
  if (linkButton) {
    if (disabled) {
      return "text-alternativ-monochrome-light";
    }
    return "text-accent-blue hover:underline focus:none";
  }
  if (disabled) {
    return "bg-gray-100 text-gray-400";
  }
  if (isPrimary) {
    return variant === "compound"
      ? "border-transparent text-white bg-black hover:bg-compound-gray-700"
      : "border-transparent text-white bg-accent-blue hover:bg-[#2b82ff]";
  }
  return "border-gray-300 text-alternativ-monochrome-dark bg-white hover:bg-gray-50";
};

type buttonSizes = "small" | "medium" | "large" | "x-large";

const buttonSizingClasses = (size?: buttonSizes) => {
  if (size === "x-large") {
    return "px-6 py-4 text-md";
  }
  if (size === "large") {
    return "px-5 py-3 text-sm";
  }
  if (size === "medium") {
    return "px-4 py-2 text-sm";
  }

  return "px-2.5 py-1.5 text-xs";
};

export const getButtonStyles = (props: ButtonInnerProps) =>
  classNames(
    props.linkButton
      ? ""
      : "relative inline-flex items-center rounded-md border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-blue focus:ring-offset-2 focus:no-underline",
    buttonColorClasses(props.isPrimary, props.disabled, props.linkButton, props.variant),
    buttonSizingClasses(props.size),
    props.fullWidth ? "w-full justify-center" : "",
    props.additionalStyleClasses ?? ""
  );

export const InnerButton: React.FunctionComponent<ButtonInnerProps> = (props: ButtonInnerProps) => {
  return (
    <>
      <span
        className={classNames(
          "inline-flex items-center",
          props.showLoadingIconOnSubmitting && props.submitting ? "invisible" : "visible",
          props.linkButton ? "hover:underline" : ""
        )}
      >
        {props.children}
      </span>
      <div
        className={classNames(
          "absolute left-1/2 -translate-x-1/2",
          props.showLoadingIconOnSubmitting && props.submitting ? "visible" : "invisible"
        )}
      >
        <LoadingIcon
          additionalStyleClasses="mr-0"
          type={props.isPrimary && !props.disabled ? "LIGHT" : "DARK"}
        />
      </div>
    </>
  );
};

const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => (
  <button
    type={props.isSubmit ? "submit" : "button"}
    {...(props.isSubmit && props.form && { form: props.form })}
    {...(props.onClick && { onClick: props.onClick })}
    {...(props.disabled && { disabled: props.disabled })}
    className={getButtonStyles(props)}
    data-at={props.dataAt}
  >
    <InnerButton {...props} />
  </button>
);

export default Button;
